body,
html, .root { 
    background-color: white;
    font-family: 'Open Sans', sans-serif; 
    background-image: url(/src/components/Logos/img/background.png);
    background-size: cover;
    background-position: 50% -344px;
  }

* { 
    margin: 0; 
    padding: 0; 
    box-sizing: border-box; 
  }
     
  /* Register -- Botão de voltar */ 
.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.makeStyles-arrow-14 {
  color: rgba(206, 95, 24);

 
}